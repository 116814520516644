<template>
    <form class="form" novalidate="novalidate" id="st_urbanism_regulations_form">
        <template>
            <st-section
            :collapsible="false"
            :isCollapsed="false"
            key="st_urbanism_regulations_form_uat_section"
            collapsibleId="st_urbanism_regulations_form_uat_section"
            >
                <template #body>
                    <div class="row">
                        <div class="col-sm col-md-3">
                            <div class="form-group">
                                <label>
                                    {{ fields.territorial_administrative_unit_id.label }}:
                                </label>
                                <st-autocomplete
                                    v-model="model[fields.territorial_administrative_unit_id.name]"
                                    :options="fields.territorial_administrative_unit_id.options"
                                    :ref="fields.territorial_administrative_unit_id.name"
                                    :name="fields.territorial_administrative_unit_id.name"
                                    :fields="fields"
                                    :model="model"
                                    :formValidation="fv"
                                    searchable
                                    :disabled="isDisabled"
                                    @change="uatChanged($event)"
                                >
                                </st-autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-8">
                        <div class="col-sm col-md-6">
                            <div class="form-group">
                                <label>
                                    {{ fields.name.label }}
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.name.name"
                                        :name="fields.name.name"
                                        v-model="model[fields.name.name]"
                                        :disabled="isDisabled"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm col-md-6">
                            <div class="form-group">
                                <label>
                                    {{ fields.symbol.label }}
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.symbol.name"
                                        :name="fields.symbol.name"
                                        v-model="model[fields.symbol.name]"
                                        :disabled="isDisabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </template>
            </st-section>
        </template>

        <div class="row mt-4" v-if="canReviewStaff">
            <div class="col-6">
                <div class="form-group">
                    <label>
                        {{ $t('URBANISM_REGULATIONS_EDIT.CHECK_EXISTING_UTR_DESC') }}:
                    </label>
                    <div>
                        <st-button :callback="checkUTR">
                            {{ $t('URBANISM_REGULATIONS_EDIT.CHECK_EXISTING_UTR') }}
                        </st-button>
                        <span class="ml-8" v-if="existingUtrChecked">
                            <span class="card-action text-danger" v-if="existingUtr">
                                {{ $t('URBANISM_REGULATIONS_EDIT.UTR_ALREADY_EXISTS', [model[fields.symbol.name]]) }}
                            </span>
                            <span class="text-success" v-else>
                                {{ $t('URBANISM_REGULATIONS_EDIT.UTR_KEY_IS_AVAILABLE', [model[fields.symbol.name]]) }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>
                        {{ $t('URBANISM_REGULATIONS_EDIT.SYNC_UTR_DESC') }}:
                    </label>
                    <div>
                        <st-button :callback="confirmSaveUtr">
                            {{ $t('URBANISM_REGULATIONS_EDIT.SYNC_UTR') }}
                        </st-button>
                    </div>
                </div>
            </div>
        </div>

        <template>
            <st-section
            collapsible
            isCollapsed
            ref="st_urbanism_regulations_form_section1"
            key="st_urbanism_regulations_form_section1"
            collapsibleId="st_urbanism_regulations_form_section1"
            >
                <template #header>
                    {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_LABEL', ['I']) }}: {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_1') }}
                </template>
                <template #body>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [1]) }}: {{ fields.article_1.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_1.name"
                                        :name="fields.article_1.name"
                                        v-model="model[fields.article_1.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [2]) }}: {{ fields.article_2.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_2.name"
                                        :name="fields.article_2.name"
                                        v-model="model[fields.article_2.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </st-section>
        </template>

        <template>
            <st-section
            collapsible
            isCollapsed
            ref="st_urbanism_regulations_form_section2"
            key="st_urbanism_regulations_form_section2"
            collapsibleId="st_urbanism_regulations_form_section2"
            >
                <template #header>
                    {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_LABEL', ['II']) }}: {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_2') }}
                </template>
                <template #body>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [3]) }}: {{ fields.article_3.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_3.name"
                                        :name="fields.article_3.name"
                                        v-model="model[fields.article_3.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [4]) }}: {{ fields.article_4.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_4.name"
                                        :name="fields.article_4.name"
                                        v-model="model[fields.article_4.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [5]) }}: {{ fields.article_5.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_5.name"
                                        :name="fields.article_5.name"
                                        v-model="model[fields.article_5.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </st-section>
        </template>

        <template>
            <st-section
            collapsible
            isCollapsed
            ref="st_urbanism_regulations_form_section3"
            key="st_urbanism_regulations_form_section3"
            collapsibleId="st_urbanism_regulations_form_section3"
            >
                <template #header>
                    {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_LABEL', ['III']) }}: {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_3') }}
                </template>
                <template #body>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [6]) }}: {{ fields.article_6.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_6.name"
                                        :name="fields.article_6.name"
                                        v-model="model[fields.article_6.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [7]) }}: {{ fields.article_7.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_7.name"
                                        :name="fields.article_7.name"
                                        v-model="model[fields.article_7.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [8]) }}: {{ fields.article_8.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_8.name"
                                        :name="fields.article_8.name"
                                        v-model="model[fields.article_8.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [9]) }}: {{ fields.article_9.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_9.name"
                                        :name="fields.article_9.name"
                                        v-model="model[fields.article_9.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [10]) }}: {{ fields.article_10.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_10.name"
                                        :name="fields.article_10.name"
                                        v-model="model[fields.article_10.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [11]) }}: {{ fields.article_11.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_11.name"
                                        :name="fields.article_11.name"
                                        v-model="model[fields.article_11.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [12]) }}: {{ fields.article_12.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_12.name"
                                        :name="fields.article_12.name"
                                        v-model="model[fields.article_12.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [13]) }}: {{ fields.article_13.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_13.name"
                                        :name="fields.article_13.name"
                                        v-model="model[fields.article_13.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [14]) }}: {{ fields.article_14.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_14.name"
                                        :name="fields.article_14.name"
                                        v-model="model[fields.article_14.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [15]) }}: {{ fields.article_15.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_15.name"
                                        :name="fields.article_15.name"
                                        v-model="model[fields.article_15.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [16]) }}: {{ fields.article_16.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_16.name"
                                        :name="fields.article_16.name"
                                        v-model="model[fields.article_16.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </st-section>
        </template>

        <template>
            <st-section
            collapsible
            isCollapsed
            ref="st_urbanism_regulations_form_section4"
            key="st_urbanism_regulations_form_section4"
            collapsibleId="st_urbanism_regulations_form_section4"
            >
                <template #header>
                    {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_LABEL', ['IV']) }}: {{ $t('URBANISM_REGULATIONS_EDIT.SECTION_4') }}
                </template>
                <template #body>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [17]) }}: {{ fields.article_17.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_17.name"
                                        :name="fields.article_17.name"
                                        v-model="model[fields.article_17.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>
                                    {{ $t('URBANISM_REGULATIONS_EDIT.ARTICLE_LABEL', [18]) }}: {{ fields.article_18.label }}
                                </label>
                                <div>
                                    <b-form-textarea
                                        :ref="fields.article_18.name"
                                        :name="fields.article_18.name"
                                        v-model="model[fields.article_18.name]"
                                        :disabled="isDisabled"
                                        :rows="rowNum"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </st-section>
        </template>

    </form>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UrbanismRegulationsModel } from "@/modules/applications/models/urbanism-regulations-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const rluCategories = ['RLU_RT', 'RLU_RJ', 'RLU_RE', 'RLU_POT', 'RLU_CUT', 'RLU_RDA'];
const rluCategoriesToArticlesMap = {
    'RLU_RT': ['article_3', 'article_4', 'article_5', 'article_6', 'article_7', 'article_8', 'article_9', 'article_10', 'article_11'],
    'RLU_RJ': ['article_1'],
    'RLU_RE': ['article_12', 'article_13', 'article_14'],
    'RLU_POT': ['article_17'],
    'RLU_CUT': ['article_18'],
    'RLU_RDA': ['article_2', 'article_15', 'article_16'],
};

const { fields } = UrbanismRegulationsModel;

const formSchema = new FormSchema([
    fields.is_existing_utr,
    fields.ru_scope,
    fields.territorial_administrative_unit_id,
    fields.territorial_administrative_unit,
    fields.name,
    fields.symbol,
    fields.article_1,
    fields.article_2,
    fields.article_3,
    fields.article_4,
    fields.article_5,
    fields.article_6,
    fields.article_7,
    fields.article_8,
    fields.article_9,
    fields.article_10,
    fields.article_11,
    fields.article_12,
    fields.article_13,
    fields.article_14,
    fields.article_15,
    fields.article_16,
    fields.article_17,
    fields.article_18,
]);

export default {
    name: "UrbanismRegulationsForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        ruScope: {
            type: String,
            require: true,
        },
        invalidSections: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            existingUtrChecked: false,
            existingUtr: null,
        };
    },
    watch: {
        'model.symbol'() {
            this.existingUtrChecked = false;
        },
        'invalidSections'(sections) {
            if(!sections.length) return;

            rluCategories.forEach( (v) => {
                const sectionCheck = rluCategoriesToArticlesMap[v].filter( (cat) => sections.indexOf(cat) !== -1);

                if(sectionCheck?.length && this.$refs[sectionCheck[0]]?.$parent?.$parent?.isCollapsed) {
                    // un-collapse the invalid section on submit. if the template structure changes, this maybe needs updated too..
                    this.$refs[sectionCheck[0]].$parent.$parent.visible = true;
                }
            });
        },
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "applications/form/record",
            loading: 'shared/loading',
        }),
        rowNum() {
            return 3;
        },
        isDisabled() {
            return this.viewMode && !this.canEditStaff;
        },
    },
    methods: {
        ...mapActions({
            getUrbanismRegulationZones: 'applications/form/findUrbanismRegulations',
            getUrbanismRegulationEntriesByZoneId: 'applications/form/findUrbanismRegulationEntriesByZone',
            fetchRluZones: "applications/urbanismRegulationZonesForm/get",
            doCreateRluZone: 'applications/urbanismRegulationZonesForm/create',
            doUpdateRLUItems: "applications/urbanismRegulationZonesForm/updateItems",
            searchRlu: 'applications/urbanismRegulationZonesList/doSearchUnique',
        }),
        uatChanged() {
            const uats = this.fields.territorial_administrative_unit_id.options;
            this.model.territorial_administrative_unit = uats.find( v => v.value === this.model.territorial_administrative_unit_id)?.text;
        },
        async fetchUTR(params) {
            const { data } = await this.searchRlu(params);
            return data.length ? data[0] : null;
        },
        async checkUTR(markChecked = true, symbolOverride = null) {
            const utrSymbol = !!symbolOverride ? symbolOverride : this.model[fields.symbol.name];
            this.existingUtr = await this.fetchUTR({
                tenant_id: this.model[fields.territorial_administrative_unit_id.name],
                name: utrSymbol,
                type: this.ruScope,
            });

            if(markChecked) {
                this.existingUtrChecked = true;
            }
        },
        async confirmSaveUtr() {
            await this.checkUTR(false);

            if(!this.existingUtr) {
                this.saveUtr();
            } else {
                const oldSymbol = this.model[fields.symbol.name];
                const symbolSuffix = Math.random().toString(36).slice(-4);
                const newSymbol = `${oldSymbol}_${symbolSuffix}`

                this.$alert({
                    type: 'warning',
                    text: this.$t('URBANISM_REGULATIONS_EDIT.OVERWRITE_EXISTING_UTR_WARN', [oldSymbol, newSymbol]),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.saveUtr(newSymbol);
                    }
                });
            }
        },
        async saveUtr(newSymbol) {
            if(newSymbol) {
                // comment this, probably we should just leave the old symbol on the UI, otherwise symbol suffix is readded when saved again
                // this.model[fields.symbol.name] = newSymbol;
            }

            const savedSymbol = newSymbol ?? this.model[fields.symbol.name];

            const tenant_name = this.fields.territorial_administrative_unit_id.options.find(
                (el) => el.value === this.model.territorial_administrative_unit_id
            )?.text;

            const zonePayload = {
                display_name: this.model[fields.name.name],
                tenant_id: this.model[fields.territorial_administrative_unit_id.name],
                name: savedSymbol,
                type: this.ruScope,
                tenant_name,
            };

            // save new UTR
            await this.doCreateRluZone(zonePayload);
            await this.checkUTR(false, savedSymbol);

            const rlus = await this.getUrbanismRegulationEntriesByZoneId(this.existingUtr.id);
            let payload = [];
            for(let i = 0; i < rluCategories.length; i++) {
                const rluCat = rluCategories[i];
                const curRlu = rlus.find( v => v.category_key == rluCat);

                let content = '';
                rluCategoriesToArticlesMap[rluCat].forEach((v) => {
                    content += `${this.model[v]}\n`
                });

                const currentRlu = {
                    id: curRlu.id,
                    zone_key: curRlu['zone_key'],
                    category_key: rluCat,
                    tenant_name,
                    content,
                    content_html: content,
                };

                payload.push(currentRlu);
            }

            // save new RLU/UTR zones
            await this.doUpdateRLUItems({zone_id: this.existingUtr.id, values: payload});
        },
    },
    async created() {
        if (this.item) {
            this.model = formSchema.initialValues(this.item);
        } else {
            this.model = formSchema.initialValues({
                is_existing_utr: false,
                ru_scope: this.ruScope,
                county_id: this.defaultCountyId,
            });
        }
    },
    mounted() {
        this.fv = createFormValidation("st_urbanism_regulations_form", this.rules);
    },
};
</script>
