import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { UtrField } from '@/shared/common-fields/utr-field';
import { CountyField } from '@/shared/common-fields/county-field';

const label = (name) => i18n.t(`URBANISM_REGULATIONS_EDIT.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`URBANISM_REGULATIONS_EDIT.PLACEHOLDERS.${name.toUpperCase()}`);
// const enumLabel = (name, value) => i18n.t(`URBANISM_REGULATIONS_EDIT.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    is_existing_utr: new BooleanField('is_existing_utr', label('is_existing_utr'), { required: false }),
    ru_scope: new StringField('ru_scope', label('ru_scope'), { required: true }),
    county: new StringField('county', label('county')),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true },
        { placeholder: placeholder('county')},
        [],
    ),
    territorial_administrative_unit: new StringField('territorial_administrative_unit', label('territorial_administrative_unit')),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
    ),
    utr_id: UtrField.relationToOne(
        'utr_id',
        label('utr_id'),
        { autocompleteRequired: true,  firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'territorial_administrative_unit_id'
        }],
        { asyncData: false }
    ),
    utr: new StringField('utr', label('utr')),
    name: new StringField('name', label('name'), { required: false }),
    symbol: new StringField('symbol', label('symbol'), { required: true }),
    // Regim tehnic
    rlu_rt: new StringField('rlu_rt', label('rlu_rt'), { required: false }),
    // Regim juridic
    rlu_rj: new StringField('rlu_rj', label('rlu_rj'), { required: false }),
    // Regim economic
    rlu_re: new StringField('rlu_re', label('article_18'), { required: false }),
    // POT
    rlu_pot: new StringField('rlu_pot', label('rlu_pot'), { required: false }),
    // CUT
    rlu_cut: new StringField('rlu_cut', label('rlu_cut'), { required: false }),
    // Regimul de actualizare
    rlu_rda: new StringField('rlu_rda', label('rlu_rda'), { required: false }),
};

export class UrbanismRegulationsViewModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
