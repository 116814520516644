import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class UtrField {
    static relationToOne(name, label, rules, filterParams, children, options) {

        return new RelationToOneField(
            name,
            label,
            (param) => {
                return new Promise((resolve, reject) => {
                    if (!param.hasOwnProperty('parent_id') || !param.parent_id) {
                        resolve([]);
                    } else if (param.parent_id) {
                        const query = param?.parent_id ? `tenant_id eq '${param.parent_id}'` : '';
                        ApiService.query('/urbanism-regulation-zones', {
                            params: {
                                query,
                                sort: '-created_date',
                                limit: 100,
                                skip: 0,
                            }
                        })
                        .then((response) => resolve(response.data))
                        .catch((error) => reject(error));
                    }
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                    ru_scope: record.type,
                    symbol: record.name,
                    name: record.display_name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
