<template>
    <st-modal
        id="urbanism-regulations-edit-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        size="xl"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0 mt-4">
                {{ modalTitle }}
            </p>
        </template>
        <template #body>
            <div class="card" v-if="!item">
                <div class="card-body">
                    <div class="font-size-h6 font-weight-bold">
                        {{ $t('URBANISM_REGULATIONS_EDIT.MODAL.OPTIONS.LABEL') }}
                    </div>
                    <div class="mt-4">
                        <div class="d-inline-block pr-6">
                            <b-form-radio
                                size="lg"
                                :value="EXISTING_UTR"
                                v-model="utrOption"
                            >
                                {{ $t('URBANISM_REGULATIONS_EDIT.MODAL.OPTIONS.LOAD_EXISTING_UTR') }}
                            </b-form-radio>
                        </div>
                        <div class="d-inline-block pr-6">
                            <b-form-radio
                                size="lg"
                                :value="NEW_UTR"
                                v-model="utrOption"
                            >
                                {{ $t('URBANISM_REGULATIONS_EDIT.MODAL.OPTIONS.ADD_NEW_UTR') }}
                            </b-form-radio>
                        </div>
                    </div>
                </div>
            </div>

            <urbanism-regulations-details
                :item="item"
                :viewMode="viewMode"
                :canReviewStaff="canReviewStaff"
                :canEditStaff="canEditStaff"
                :ruScope="ruScope"
                :isExistingUtr="isExistingUtr"
                @submitForm="submit"
                @cancelForm="cancel"
            ></urbanism-regulations-details>
        </template>
    </st-modal>
</template>

<script>
import UrbanismRegulationsDetails from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsDetails.vue';

export default {
    name: "UrbanismRegulationsModal",
    props: {
        item: {
            type: Object,
            required: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
        ruScope: {
            type: String,
            require: true,
        },
    },
    components: {
        UrbanismRegulationsDetails,
    },
    data() {
        const NEW_UTR = 'new_utr';
        const EXISTING_UTR = 'existing_utr';

        return {
            viewMode: false,
            NEW_UTR,
            EXISTING_UTR,
            utrOption: NEW_UTR,
        };
    },
    computed: {
        isExistingUtr() {
            if(this.item) {
                return this.item.is_existing_utr;
            } else {
                return this.utrOption == this.EXISTING_UTR;
            }
        },
        modalTitle() {
            if(this.viewMode && !this.canEditStaff) {
                return this.$t('URBANISM_REGULATIONS_EDIT.MODAL.VIEW_HEADER');
            }
            if(this.item) {
                return this.$t('URBANISM_REGULATIONS_EDIT.MODAL.EDIT_HEADER');
            }
            return this.$t('URBANISM_REGULATIONS_EDIT.MODAL.ADD_HEADER');
        },
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show(viewMode = false) {
            this.viewMode = viewMode;
            this.$refs["modal"].show();
        },
        submit(data) {
            this.$emit("processUrbanismRegulations", data);
            this.hide();
        },
        cancel() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
