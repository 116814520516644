<template>
    <div>
        <urbanism-regulations-form
            v-if="!isExistingUtr"
            ref="urbanismRegulations"
            :item="item"
            :viewMode="viewMode"
            :ruScope="ruScope"
            :canReviewStaff="canReviewStaff"
            :invalidSections="invalidSections"
            :canEditStaff="canEditStaff"
        >
        </urbanism-regulations-form>

        <urbanism-regulations-view-form
            v-if="isExistingUtr"
            ref="urbanismRegulationsView"
            :item="item"
            :viewMode="viewMode"
            :ruScope="ruScope"
            :canReviewStaff="canReviewStaff"
            :invalidSections="invalidSections"
            :canEditStaff="canEditStaff"
        >
        </urbanism-regulations-view-form>

        <div class="d-flex justify-content-between mt-8">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("APPLICATION.MODALS.CANCEL") }}
            </st-button>
            <st-button v-if="!viewMode || canEditStaff" size="large" :callback="submit">
                {{ submitButtonText }}
            </st-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UrbanismRegulationsModel } from "@/modules/applications/models/urbanism-regulations-model";
import UrbanismRegulationsForm from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsForm.vue'
import UrbanismRegulationsViewForm from '@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsViewForm.vue'

const { fields } = UrbanismRegulationsModel;

export default {
    name: "UrbanismRegulationsDetails",
    components: {
        UrbanismRegulationsForm,
        UrbanismRegulationsViewForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
        ruScope: {
            type: String,
            require: true,
        },
        isExistingUtr: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            fields,
            model: null,
            selectedEntity: null,
            invalidSections: [],
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('APPLICATION.MODALS.SAVE') : this.$t('APPLICATION.MODALS.ADD');
        },
    },
    methods: {
        async validateRlu() {
            const fieldRefs = this.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key] && fieldRefs[key].fields
            );

            const selectedEntity = fieldRefs[selectedEntityKey];
            const status = await selectedEntity.fv.validate();
            const urbanismRegulationsDetails = { ...selectedEntity.model};

            return {
                status,
                urbanismRegulationsDetails,
            };
        },
        show() {
            this.$refs["modal"].show();
        },
        cancel() {
            this.$emit('cancelForm');
        },
        async submit() {
            this.invalidSections = [];
            const rlu = await this.validateRlu();

            if (rlu.status === 'Valid') {
                if (this.item) {
                    rlu.index = this.item.index;
                }
                this.$emit("submitForm", rlu);
            } else {
                Object.keys(rlu.urbanismRegulationsDetails).forEach(v => {
                    if(v.indexOf('article') !== -1 && !rlu.urbanismRegulationsDetails[v]) {
                        if(this.invalidSections.indexOf(v) == -1) {
                            this.invalidSections.push(v);
                        }
                    }
                });
            }
        },
    },
    created() {
        //
    },
    mounted() {
        this.selectedEntity = this.item ?? null;
    },
};
</script>
