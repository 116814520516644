<template>
    <div>
        <div class="d-flex flex-row-reverse">
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
            <div v-if="viewMode">
                <div class="card-action" @click="$emit(action, {item, index})">
                    <i :class="`fas fa-${icon}`"></i>
                </div>
            </div>
        </div>
        <div class="mt-2">
            <span class="font-weight-bold">{{ $t("URBANISM_REGULATIONS_EDIT.CARD.NAME") }}: </span>
            <span>{{ item.name }}</span>
        </div>
        <div class="mt-4">
            <span class="font-weight-bold">{{ $t("URBANISM_REGULATIONS_EDIT.CARD.SYMBOL") }}: </span>
            <span>{{ item.symbol }}</span>
        </div>
        <div class="mt-4">
            <span class="font-weight-bold">{{ $t("URBANISM_REGULATIONS_EDIT.CARD.UAT") }}: </span>
            <span>{{ item.territorial_administrative_unit }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: 'UrbanismRegulationsItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        canEditStaff: {
            type: Boolean,
            default: false,
        },
        canReviewStaff: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        icon() {
            return this.canEditStaff || this.canReviewStaff ? 'edit' : 'eye';
        },
        action() {
            return this.canEditStaff ? 'edit' : 'view';
        },
    },
}
</script>
